'use client';

import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {ErrorBoundary} from 'react-error-boundary';
import './app.css';
import {register} from 'swiper/element/bundle';
import {EnquireForm} from './components/Buttons/EnquireForm';
import {VideoPlayButton} from './components/Buttons/VideoPlayButton';
import {AccordionBlock} from './components/PageSections/AccordionBlock';
import {FranchiseeBlock} from './components/PageSections/FranchiseeBlock';
import {MobileListBlock} from './components/PageSections/MobileListBlock';
import {MobileNav} from './components/PageSections/MobileNav';
import {PropertyHolder} from './components/PageSections/PropertyHolder';
import {PropertyImageCarousel} from './components/PageSections/PropertyImageCarousel';
import {PropertyManagerListingPage} from './components/PageSections/PropertyManagerListingPage';
import {RentalListingBlock} from './components/PageSections/RentalListingBlock';
import {SelectRegion} from './components/PageSections/SelectRegion';
import {TestimonialsBlock} from './components/PageSections/TestimonialsBlock';

register();

document.addEventListener('DOMContentLoaded', function () {
  let prevScrollPos = document.documentElement.scrollTop;
  const nav = document.getElementById('navbar');
  window.onscroll = function () {
    //less than tailwind lgr breakpoint (custom)
    if (!nav || window.innerWidth < 1280) return;
    let currentScrollPos = document.documentElement.scrollTop;
    if (prevScrollPos > currentScrollPos) {
      nav.style.top = '0';
    } else {
      nav.style.top = `-${nav?.offsetHeight}px`;
    }
    prevScrollPos = currentScrollPos;
  };
});

const ErrorComponent = ({children, content}: {
  children: React.ReactNode,
  content: string
}) => (
  <ErrorBoundary fallback={
    <h2 className="text-brand-blue sm:text-2.5xl text-lg sm:my-24 mt-9 leading-normal text-center max-w-155 mx-auto">{content}</h2>
  }>
    {children}
  </ErrorBoundary>
);

const propertyHolderSections = document.querySelectorAll('[data-react="propertyHolder"]');
Array.from(propertyHolderSections).forEach(propertyHolderSection => {
  if (propertyHolderSection instanceof HTMLElement) {
    const root = ReactDOM.createRoot(propertyHolderSection!);

    root.render(
      <ErrorComponent
        content="We apologize for the inconvenience. This page is currently experiencing technical difficulties. Our team has been notified and is working diligently to resolve the issue. Please check back later. Thank you for your patience!">
        <PropertyHolder/>
      </ErrorComponent>
    );
  }
});

const propertyManagerListingPages = document.querySelectorAll('[data-react="findARentalManager"]');
Array.from(propertyManagerListingPages).forEach(propertyManagerListingPage => {
  if (propertyManagerListingPage instanceof HTMLElement) {
    const root = ReactDOM.createRoot(propertyManagerListingPage!);

    root.render(
      <ErrorComponent
        content="We apologize for the inconvenience. This page is currently experiencing technical difficulties. Our team has been notified and is working diligently to resolve the issue. Please check back later. Thank you for your patience!">
        <PropertyManagerListingPage/>
      </ErrorComponent>
    );
  }
});

const selectRegionBoxes = document.querySelectorAll('[data-react="selectRegion"]');
Array.from(selectRegionBoxes).forEach(selectRegion => {
  if (selectRegion instanceof HTMLElement) {
    const root = ReactDOM.createRoot(selectRegion!);

    const reactProps = JSON.parse(selectRegion.dataset.props || '{}');
    root.render(<SelectRegion redirectURL={reactProps.redirectURL ?? ''} />);
  }
});

const accordionBlocks = document.querySelectorAll('[data-react="accordionBlock"]');
Array.from(accordionBlocks).forEach(accordionBlock => {
  if (accordionBlock instanceof HTMLElement) {
    const root = ReactDOM.createRoot(accordionBlock!);

    const reactProps = JSON.parse(accordionBlock.dataset.props || '{}');
    root.render(<AccordionBlock questions={reactProps.questions} containsImage={reactProps.containsImage}
                                containsNumbers={reactProps.containsNumbers}/>);
  }
});

const messageMeButtons = document.querySelectorAll('[data-react="messageMe"]');
Array.from(messageMeButtons).forEach(messageMeButton => {
  if (messageMeButton instanceof HTMLElement) {
    const root = ReactDOM.createRoot(messageMeButton!);

    const reactProps = JSON.parse(messageMeButton.dataset.props || '{}');
    root.render(
      <EnquireForm className="btn-blue mt-9" contextID={reactProps.managerID} buttonTitle={'Message me'} linkedIn={reactProps.linkedIn}
                   siteKey={reactProps.recaptcha_site_key} facebook={reactProps.facebook} emailField={reactProps.managersEmail}
                   nameField={reactProps.managersName} phoneField={reactProps.managersPhone}/>);
  }
});

const testimonialsBlocks = document.querySelectorAll('[data-react="testimonialsBlock"]');
Array.from(testimonialsBlocks).forEach(testimonialBlocks => {
  if (testimonialBlocks instanceof HTMLElement) {
    const root = ReactDOM.createRoot(testimonialBlocks!);

    const reactProps = JSON.parse(testimonialBlocks.dataset.props || '{}');
    root.render(<TestimonialsBlock testimonials={reactProps.testimonials} title={reactProps.title}/>);
  }
});

const rentalListings = document.querySelectorAll('[data-react="rentalListingsBlock"]');
Array.from(rentalListings).forEach(rentalListing => {
  if (rentalListing instanceof HTMLElement) {
    const root = ReactDOM.createRoot(rentalListing!);

    const reactProps = JSON.parse(rentalListing.dataset.props || '{}');
    root.render(<RentalListingBlock listings={reactProps.rentalListings}/>);
  }
});

const franchiseesBlocks = document.querySelectorAll('[data-react="franchiseeBlock"]');
Array.from(franchiseesBlocks).forEach(franchiseesBlock => {
  if (franchiseesBlock instanceof HTMLElement) {
    const root = ReactDOM.createRoot(franchiseesBlock!);

    const reactProps = JSON.parse(franchiseesBlock.dataset.props || '{}');
    root.render(<FranchiseeBlock email={reactProps.email} franchiseeContentID={reactProps.franchisee_content_id}
                                 recaptchaSiteKey={reactProps.recaptcha_site_key}/>);
  }
});

const propertyListingCarousels = document.querySelectorAll('[data-react="propertyListingCarousel"]');
Array.from(propertyListingCarousels).forEach(propertyListingCarousel => {
  if (propertyListingCarousel instanceof HTMLElement) {
    const root = ReactDOM.createRoot(propertyListingCarousel!);

    const reactProps = JSON.parse(propertyListingCarousel.dataset.props || '{}');

    root.render(
      <ErrorComponent
        content="We apologize for the inconvenience. This page is currently experiencing technical difficulties. Our team has been notified and is working diligently to resolve the issue. Please check back later. Thank you for your patience!">
        {reactProps?.images?.length !== 0 && <PropertyImageCarousel images={reactProps.images}/>}
      </ErrorComponent>
    );
  }
});

const videoPlayButtons = document.querySelectorAll('[data-react="videoPlayButton"]');
Array.from(videoPlayButtons).forEach(videoPlayButton => {
  if (videoPlayButton instanceof HTMLElement) {
    const root = ReactDOM.createRoot(videoPlayButton!);

    const reactProps = JSON.parse(videoPlayButton.dataset.props || '{}');

    root.render(
      <ErrorComponent
        content="We cannot load this video right now. Check back here soon">
        <VideoPlayButton video={reactProps.video} youtube={reactProps.youtube}/>
      </ErrorComponent>
    );
  }
});

const mobileNavs = document.querySelectorAll('[data-react="mobileNav"]');
Array.from(mobileNavs).forEach(mobileNav => {
  if (mobileNav instanceof HTMLElement) {
    const root = ReactDOM.createRoot(mobileNav!);

    const reactProps = JSON.parse(mobileNav.dataset.props || '{}');
    root.render(<MobileNav pages={reactProps.pages} findARentalManagerLink={reactProps.findARentalManagerLink}/>);
  }
});

const mobileListBlocks = document.querySelectorAll('[data-react="mobileListBlock"]');
Array.from(mobileListBlocks).forEach(mobileListBlock => {
  if (mobileListBlock instanceof HTMLElement) {
    const root = ReactDOM.createRoot(mobileListBlock!);

    const reactProps = JSON.parse(mobileListBlock.dataset.props || '{}');
    root.render(<MobileListBlock listItems={reactProps.listItems}/>);
  }
});
