'use strict';
import * as Accordion from '@radix-ui/react-accordion';
import cls from 'classnames';
import React from 'react';

interface Question {
    question: string;
    answer: string;
}

export const AccordionBlock = ({questions, containsImage, containsNumbers}: { questions: Question[], containsImage: boolean, containsNumbers: boolean }) => (
    <div className={cls('flex flex-col', containsImage ? 'xl:w-155 w-full' : 'w-full')}>
        <Accordion.Root
            className={cls('flex flex-col w-full mx-auto', containsImage ? 'lg:w-155 xl:mx-0' : 'lg:w-3/5')}
            type="single"
            collapsible
        >
            {
                questions.map((question, index) => (
                        <Accordion.Item value={'item-' + index} key={index} className="duration-300 data-[state=open]:shadow-home data-[state=closed]:border-b border-brand-blue/30 data-[state=open]:rounded-box data-[state=closed]:animate-hideItem">
                            <div className="flex space-x-6">
                                {containsNumbers && <div className="numberLemon my-11 ml-9 flex items-center justify-center text-xs font-semibold text-white rounded-lemon w-6 h-6 text-center">{index + 1}</div>}
                                <div className={cls('flex flex-col w-full', !containsNumbers && 'ml-9')}>
                                    <Accordion.Header className="py-9 data-[state=open]:pb-4 data-[state=closed]:pb-9  pr-9 ease-in-out duration-300 flex space-between items-center justify-between data-[state=closed]:font-semibold data-[state=open]:font-normal data-[state=open]:text-1.5xl">
                                        <span className="text-brand-blue">
                                            {question.question}
                                        </span>
                                        <Accordion.Trigger className="focus-within:border-0 focus:border-0 AccordionTrigger">
                                            <svg width="40" className="text-brand-blue hover:text-white hover:fill-brand-blue ease-in-out duration-300 AccordionChevron" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="14" className="stroke-brand-blue" strokeWidth="2"/>
                                                <path d="M23.364 18.2933C23.7545 18.6838 23.7545 19.317 23.364 19.7075L20.5356 22.5359C20.145 22.9265 19.5119 22.9265 19.1213 22.5359C18.7308 22.1454 18.7308 21.5123 19.1213 21.1217L21.9498 18.2933C22.3403 17.9028 22.9735 17.9028 23.364 18.2933Z" fill="currentColor"/>
                                                <path d="M20.5356 22.5359C20.145 22.9265 19.5119 22.9265 19.1213 22.5359L16.2929 19.7071C15.9024 19.3166 15.9024 18.6834 16.2929 18.2929C16.6834 17.9024 17.3166 17.9024 17.7071 18.2929L20.5356 21.1213C20.9261 21.5118 20.9261 22.1454 20.5356 22.5359Z" fill="currentColor"/>
                                            </svg>
                                        </Accordion.Trigger>
                                    </Accordion.Header>

                                    <Accordion.Content className="data-[state=open]:animate-hideContent data-[state=closed]:animate-showContent overflow-hidden">
                                        <div className="prose lg:prose-base prose-sm 2xl:pr-40 sm:pr-28 pr-9 pb-9" dangerouslySetInnerHTML={{__html: question.answer}}/>
                                    </Accordion.Content>
                                </div>
                            </div>
                        </Accordion.Item>
                    ),
                )
            }
        </Accordion.Root>
    </div>
);


